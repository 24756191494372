<template>
  <div class="flex flex-col gap-8 md:mx-44 m-8">
    <!-- <BreadcrumbFAQ page="Merchant" /> -->
    <ol class="flex flex-col gap-4 list-decimal text-2xl font-medium">
      <li id="sign-up">
        SIGN UP
        <div class="flex flex-col mt-4 gap-4">
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            @click="toggleOnSignUp"
            :class="[showAccordion.signUp ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="showAccordion.signUp"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>How do I sign up for my account?</p>
          </div>
          <div class="md:flex flex-row mx-8 gap-8" v-if="showAccordion.signUp">
            <div
              class="bg-gray-100 hover:bg-gray-300 px-8 py-2 rounded-md md:w-1/4 w-full flex justify-center"
            >
              <img
                src="../../../assets/register-gif.gif"
                alt=""
                class="object-cover"
              />
            </div>
            <div
              class="flex flex-col gap-4 text-base font-normal md:w-3/4 w-full mt-4 md:mt-0"
            >
              <p>Tripwe account sign up steps:</p>
              <ol class="ml-12 list-decimal">
                <li>
                  Create an account on Tripwe and fill in your Name, Mobile
                  Number and Password then click Sign Up.
                </li>
                <li>
                  Verify the account by entering the OTP* sent to your
                  SMS/WhatsApp and clicking OK
                </li>
                <li>
                  You have successfully signed up and created a Tripwe account
                </li>
              </ol>
              <p>
                *Attention, Trippers! DO NOT SHARE YOUR OTP TO ANYONE. Not even
                to anyone claiming to be Tripwe official.
              </p>
              <p>
                If you are unable to sign up for a Tripwe account, make sure the
                mobile number you are signing up is still active and has never
                been used on a Tripwe account (1 mobile number can only be used
                for 1 Tripwe account.)
              </p>
            </div>
          </div>
        </div>
      </li>
      <li>
        LOGIN
        <div class="flex flex-col mt-4 gap-4">
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            @click="toggleOnLogin"
            :class="[showAccordion.login ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="showAccordion.login"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>How do I login to my account?</p>
          </div>
          <div class="md:flex flex-row mx-8 gap-8" v-if="showAccordion.login">
            <div
              class="bg-gray-100 hover:bg-gray-300 px-8 py-2 rounded-md md:w-1/4 w-full flex justify-center"
            >
              <img
                src="../../../assets/login-gif.gif"
                alt=""
                class="object-cover"
              />
            </div>
            <div
              class="flex flex-col gap-4 text-base font-normal md:w-3/4 w-full mt-4 md:mt-0"
            >
              <p>Tripwe account login steps:</p>
              <ol class="ml-12 list-decimal">
                <li>
                  Enter Mobile Number and Password that you registered on Tripwe
                </li>
                <li>Click ‘Login’</li>
              </ol>
            </div>
          </div>
        </div>
      </li>
      <li>
        PROFILE
        <div class="flex flex-col mt-4 gap-4">
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            @click="toggleOnProfile"
            :class="[showAccordion.profile ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="showAccordion.profile"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>How do I change my profile account?</p>
          </div>
          <div class="md:flex flex-row mx-8 gap-8" v-if="showAccordion.profile">
            <div
              class="bg-gray-100 hover:bg-gray-300 px-8 py-2 rounded-md md:w-1/4 w-full flex justify-center"
            >
              <img
                src="../../../assets/login-gif.gif"
                alt=""
                class="object-cover"
              />
            </div>
            <div
              class="flex flex-col gap-4 text-base font-normal md:w-3/4 w-full mt-4 md:mt-0"
            >
              <ol class="ml-12 list-decimal">
                <li>
                  Click Account on the main page of the Tripwe application
                </li>
                <li>
                  Click Account on the main page of the Tripwe application
                </li>
                <li>Click My Profile</li>
                <li>Make the changes you want to the profile</li>
                <li>Click Save</li>
              </ol>
            </div>
          </div>
        </div>
      </li>
      <li>
        FORGOT PASSWORD
        <div class="flex flex-col gap-4">
          <div class="flex flex-col mt-4 gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleOnForgotPass1"
              :class="[showAccordion.forgotPass1 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="showAccordion.forgotPass1"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>What should I do if I forget my account password?</p>
            </div>
            <ol
              class="ml-12 list-decimal text-base font-normal"
              v-if="showAccordion.forgotPass1"
            >
              <li>On the login page click ‘Forgot Password?’</li>
              <li>
                Enter Mobile Number, New Password and Repeat the New Password
              </li>
              <li>Click My Profile</li>
              <li>Click Next</li>
            </ol>
          </div>
          <div class="flex flex-col mt-4 gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleOnForgotPass2"
              :class="[showAccordion.forgotPass2 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="showAccordion.forgotPass2"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>How to change passwords?</p>
            </div>
            <ol
              class="ml-12 list-decimal text-base font-normal"
              v-if="showAccordion.forgotPass2"
            >
              <li>Click Account on the front page of the Tripwe application</li>
              <li>Click Account Settings on the account page</li>
              <li>Click My Profile</li>
              <li>Click Change Password</li>
              <li>Enter Old Password and New Password</li>
              <li>Click Save</li>
            </ol>
          </div>
          <div class="flex flex-col mt-4 gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleOnForgotPass3"
              :class="[showAccordion.forgotPass3 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="showAccordion.forgotPass2"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>Are there any restrictions on changing passwords?</p>
            </div>
            <p
              class="ml-12 text-base font-normal"
              v-if="showAccordion.forgotPass3"
            >
              There is no limit on changing the Tripwe account password
            </p>
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>
<script>
// import BreadcrumbFAQ from "../../components/BreadcrumbFAQ.vue";

export default {
  name: "AccountFAQ",
  data() {
    return {
      showAccordion: {
        signUp: false,
        login: false,
        profile: false,
        forgotPass1: false,
        forgotPass2: false,
        forgotPass3: false,
      },
    };
  },
  created() {
    this.openAccordionWithUrl();
  },
  methods: {
    toggleOnSignUp() {
      this.showAccordion.signUp = !this.showAccordion.signUp;
      this.showAccordion.login = false;
      this.showAccordion.profile = false;
      this.showAccordion.forgotPass1 = false;
      this.showAccordion.forgotPass2 = false;
      this.showAccordion.forgotPass3 = false;
    },
    toggleOnLogin() {
      this.showAccordion.signUp = false;
      this.showAccordion.login = !this.showAccordion.login;
      this.showAccordion.profile = false;
      this.showAccordion.forgotPass1 = false;
      this.showAccordion.forgotPass2 = false;
      this.showAccordion.forgotPass3 = false;
    },
    toggleOnProfile() {
      this.showAccordion.signUp = false;
      this.showAccordion.login = false;
      this.showAccordion.profile = !this.showAccordion.profile;
      this.showAccordion.forgotPass1 = false;
      this.showAccordion.forgotPass2 = false;
      this.showAccordion.forgotPass3 = false;
    },
    toggleOnForgotPass1() {
      this.showAccordion.signUp = false;
      this.showAccordion.login = false;
      this.showAccordion.profile = false;
      this.showAccordion.forgotPass1 = !this.showAccordion.forgotPass1;
      this.showAccordion.forgotPass2 = false;
      this.showAccordion.forgotPass3 = false;
    },
    toggleOnForgotPass2() {
      this.showAccordion.signUp = false;
      this.showAccordion.login = false;
      this.showAccordion.profile = false;
      this.showAccordion.forgotPass1 = false;
      this.showAccordion.forgotPass2 = !this.showAccordion.forgotPass2;
      this.showAccordion.forgotPass3 = false;
    },
    toggleOnForgotPass3() {
      this.showAccordion.signUp = false;
      this.showAccordion.login = false;
      this.showAccordion.profile = false;
      this.showAccordion.forgotPass1 = false;
      this.showAccordion.forgotPass2 = false;
      this.showAccordion.forgotPass3 = !this.showAccordion.forgotPass3;
    },
    openAccordionWithUrl() {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === "" ||
        this.$route.params.id === "signup"
      ) {
        this.showAccordion.signUp = true;
      } else if (this.$route.params.id === "login") {
        this.showAccordion.login = true;
      } else if (this.$route.params.id === "profile") {
        this.showAccordion.profile = true;
      } else if (this.$route.params.id === "forgotpass1") {
        this.showAccordion.forgotPass1 = true;
      } else if (this.$route.params.id === "forgotpass2") {
        this.showAccordion.forgotPass2 = true;
      } else if (this.$route.params.id === "forgotpass3") {
        this.showAccordion.forgotPass3 = true;
      }
    },
  },
};
</script>
